<template>
  <div class="organizationManage">
      组织管理
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.organizationManage {
    
}
</style>
